import React from 'react'
import Layout from '../components/layout'
import ContentBlock from "../components/content-block"

const NotFoundPage = () => (
  <Layout>
    <ContentBlock wrapper>
      <h1>NOT FOUND</h1>
      <p>Sorry, we can't find the page you're looking for...</p>
    </ContentBlock>
  </Layout>
)

export default NotFoundPage
